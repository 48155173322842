@import "../../styles/theme.scss";

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;

  .modal {
    height: auto;
  }

  .modalContent {
    button {
      margin-bottom: 0;
    }
  }
}
